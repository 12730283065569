import React from "react"
import Bg from "../components/Bg"
import Container from "../components/Container"
import Jumbotron from "../components/Jumbotron"
import Section from "../components/Section"
import Site from "../components/Site"
import Tiles from "../components/Tiles"
import Tile from "../components/Tile"
import "../css/new.scss"

const NewPage = () => (
  <Site
    title     = "I'm New"
    className = "hcc-new"
  >
    <article data-datocms-noindex>
      <Section tag="header" className="hcc-new-intro">
        <Container>
          <Jumbotron content="Learn more about becoming a member at Hillside"/>
        </Container>
      </Section>
      <Section className="hcc-new-index">
        <Container>
          <Tiles>
            <Tile
              className   = "is-plan-your-visit"
              size        = "custom"
              cardinality = "n"
              href        = "/plan-your-visit/"
              title       = "Plan a Visit"
              subtitle    = "Come as you are and meet our community at Hillside"
              cta         = {[{ label : "Learn More", href : "/plan-your-visit/" }]}
            >
              <Bg layers = {[{ stack : [{ url : '/new/tile-plan-your-visit.png', size : 'auto 100%' }] }]}/>
            </Tile>
            <Tile
              className   = "is-go-classes"
              size        = "custom"
              cardinality = "n"
              href        = "/go/"
              title       = "Go:Classes"
              subtitle    = "A four class journey to discover God&apos;s story for you"
              cta         = {[{ label : "Learn More", href : "/go/" }]}
            >
              <Bg layers = {[{ stack : [{ url : '/new/tile-go-classes.png', size : 'auto 100%' }] }]}/>
            </Tile>
          </Tiles>
        </Container>
      </Section>
    </article>
  </Site>
)

export default NewPage
